import {ENVIRONMENT} from '../app/core/config/environments.config';

export const environment = {
    ApiServer: "https://api.agencies.dev.fileandserve.com/",
    Environment: {
      Env: ENVIRONMENT.DEV,
    },
    MAX_FILE_SIZE: 1_000_000,
    production: false,
}
