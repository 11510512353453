import { AuthConfig } from 'angular-oauth2-oidc';
export const FsxB2CDocumentDiscoveryURL = 'https://fileandserveagencies.b2clogin.com/FileAndServeAgencies.onmicrosoft.com/B2C_1A_DEMO_EMAIL_SIGNIN/v2.0/.well-known/openid-configuration';
const FsxB2CClientId = '789592d3-9a32-480b-a712-9ded406c2cbb';
const FsxB2CScopes = 'openid profile email offline_access https://FileAndServeAgencies.onmicrosoft.com/789592d3-9a32-480b-a712-9ded406c2cbb/access_as_user';
const FsxB2CIssuer = 'https://fileandserveagencies.b2clogin.com/c4b7619d-c281-4377-9e23-794c9c0974df/v2.0/'
const FsxB2CLoginUrl = 'https://FileAndServeAgencies.b2clogin.com/FileAndServeAgencies.onmicrosoft.com/B2C_1A_DEMO_EMAIL_SIGNIN/oauth2/v2.0/authorize';
const FsxB2CLogoutUrl = 'https://FileAndServeAgencies.b2clogin.com/FileAndServeAgencies.onmicrosoft.com/signout/B2C_1A_DEMO_EMAIL_SIGNIN'

export const authConfig: AuthConfig = {
  // ToDo: Need to store these magic strings in environment variables.
  issuer: FsxB2CIssuer,
  loginUrl: FsxB2CLoginUrl,
  logoutUrl: FsxB2CLogoutUrl,
  // ToDo: I believe we need to register all possible returnUri's in the Azure AD B2C App Registration
  redirectUri: window.location.origin + '/index.html',
  clientId: FsxB2CClientId,
  responseType: 'code',
  strictDiscoveryDocumentValidation: false,
  scope: FsxB2CScopes,
  showDebugInformation: false,
  skipIssuerCheck: false,
  // timeoutFactor: .01
}
